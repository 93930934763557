<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="TestCompleteReport" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <div class="header">
                <h1>{{ this.$route.name }}</h1>
            </div>
            <a-divider/>
            <div class="report">
                <div class="report__filter">
                    <div class="filter-item">
                        <span><a-icon type="clock-circle" /> Thời gian: </span>
                        <a-range-picker
                                :locale="locale"
                                :defaultValue="[startDate, currentDate]"
                                @change="onChange"
                                format="YYYYMMDD"
                        />

                        <a-select :showSearch="true" :filterOption="filterOption" placeholder="Chọn quản lý vùng" style="min-width: 180px; margin-left: 20px" @change="onSelectSalonAreaChange">
                            <a-select-option key="all" value="all">Chọn tất cả vùng</a-select-option>
                            <a-select-option v-for="(item, index) in getSalonAreas" :key="index" :value="JSON.stringify(item)">{{ item.fullName }}</a-select-option>
                        </a-select>

                        <a-select v-model="selectedSalon" defaultValue="all" :disabled="disableSelectSalon" :showSearch="true" :filterOption="filterOption" placeholder="Chọn ds salon" style="min-width: 150px; margin-left: 20px" @change="onSelectSalonChange">
                            <a-select-option key="all" value="all">Chọn tất cả salon</a-select-option>
                            <a-select-option v-for="item in salonSelects" :key="item.id" :value="item.id">{{ item.shortName }}</a-select-option>
                        </a-select>

                        <a-button style="margin-left: 20px" type="primary" @click="handleGetReport" icon="export">Xem dữ liệu</a-button>
                    </div>
                </div>
                <div class="header__action">
                    <export-excel class="btn btn-default" :data="data" :fields="excelFields" worksheet ="Báo cáo tỉ lệ hoàn thành bài test" name="bao-cao-ti-le-hoan-thanh-bai-test.xls">
                        <a-button style="margin-left: 20px" type="primary" icon="export">Xuất file Excel</a-button>
                    </export-excel>
                </div>
            </div>
            <a-divider/>
            <div style="background-color: #ececec; padding: 20px;">
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Ngày thực hiện trung bình" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgTestCompleted}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Ngày test đạt trung bình" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgTestPass}}</p>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card :headStyle="{textAlign: 'center'}" :loading="loading" title="Tỉ lệ test đạt trung bình" :bordered="false">
                            <p style="text-align: center; font-size: 24px;">{{avgPercentPass}}%</p>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
            <a-divider/>
            <a-table :columns="columns" :dataSource="data" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="pagination">
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 8px"
                >
                    <a-input
                            v-ant-ref="c => searchInput = c"
                            :placeholder="`Tìm kiếm ${column.title}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                            style="width: 188px; margin-bottom: 8px; display: block;"
                    />
                    <a-button type="primary" @click="() => handleSearch(selectedKeys, confirm)" size="small" style="width: 90px; margin-right: 8px">Tìm kiếm</a-button>
                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined, fontWeight: filtered ? 'bold': undefined }"
                />
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
                <template slot="correctPercent" slot-scope="text, record">
                    <div :key="record.email">
                        <a-tag>{{text}}%</a-tag>
                    </div>
                </template>
            </a-table>
        </div>
    </a-layout-content>
</template>

<script>
    import moment from 'moment'
    import locale from '../../configs/datePickerLocale'
    import { mapGetters, mapActions } from 'vuex';
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const reportRepository = RepositoryFactory.get('report');
    moment.locale("vi-vn")

    export default {
        name: "TestCompleteReport",
        data() {
            return {
                searchText: '',
                searchInput: null,
                locale,
                startDate: moment().startOf('month'),
                currentDate: moment(),
                salonIds: [],
                data: [],
                disableSelectSalon: true,
                selectedSalon: 'all',
                salonSelects: [],
                loading: false,
                pagination: {
                    pageSize: 15,
                    showTotal: (total, range) => `Hiển thị từ ${range[0]} - ${range[1]} / ${total} dòng`
                },
                excelFields: {
                    'Id': 'staffId',
                    'Email': 'email',
                    'Họ và tên': 'fullName',
                    'Vị trí': 'staffType',
                    'Level': 'skillLevel',
                    'Salon': 'salon',
                    'Tổng ngày thực hiện': 'totalDays',
                    'Tổng ngày đạt': 'totalTurnPass',
                    'Tỉ lệ đạt': {
                        field: 'correctPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    }
                },
                columns: [{
                    title: 'ID',
                    dataIndex: 'staffId',
                    width: '5%',
                    key: 'id',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon'
                    },
                    onFilter: (value, record) => record.staffId.toString().toLowerCase().includes(value.toString().toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },{
                    title: 'Email',
                    dataIndex: 'email',
                    width: '10%',
                    key: 'email',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon'
                    },
                    onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },{
                    title: 'Họ và tên',
                    dataIndex: 'fullName',
                    width: '20%',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon'
                    },
                    onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    }
                }, {
                    title: 'Vị trí',
                    dataIndex: 'staffType',
                    width: '13%',
                    sorter: (a, b) => { return a.staffType.toUpperCase().localeCompare(b.staffType.toUpperCase())}
                }, {
                    title: 'Level',
                    dataIndex: 'skillLevel',
                    width: '13%',
                    sorter: (a, b) => { return a.skillLevel.toUpperCase().localeCompare(b.skillLevel.toUpperCase())}
                }, {
                    title: 'Salon',
                    dataIndex: 'salon',
                    width: '13%',
                    sorter: (a, b) => { return a.salon.toUpperCase().localeCompare(b.salon.toUpperCase())}
                }, {
                    title: 'Tổng ngày thực hiện',
                    dataIndex: 'totalDays',
                    width: '10%',
                }, {
                    title: 'Tổng ngày đạt',
                    dataIndex: 'totalTurnPass',
                    width: '10%',
                    sorter: (a, b) => { return a.totalTurnPass - b.totalTurnPass}
                }, {
                    title: 'Tỉ lệ ngày đạt',
                    width: '10%',
                    dataIndex: 'correctPercent',
                    scopedSlots: { customRender: 'correctPercent' },
                    sorter: (a, b) => { return a.correctPercent - b.correctPercent}
                }]
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons', 'getSalonAreas'
            ]),
            avgTestCompleted() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalDays;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgTestPass() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.totalTurnPass;
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            },
            avgPercentPass() {
                if (this.data.length === 0) return 0;
                let sum = this.data.reduce((total, item) => {
                    return total + item.correctPercent
                }, 0);
                return Math.round( (sum / this.data.length) *100) / 100;
            }
        },
        methods: {
            ...mapActions('authenticate',[
                'refreshToken'
            ]),
            moment,
            onChange(date) {
                this.startDate = date[0];
                this.currentDate = date[1];
            },
            async handleGetReport() {
                if (!this.salonIds.length) {
                    await this.$message.error('Danh sách salon không được bỏ trống!!');
                    return;
                }

                try {
                    this.loading = true;
                    let response = await reportRepository.testCompleteReport({
                        workDateStart: this.startDate.format("YYYYMMDD"),
                        workDateEnd: this.currentDate.format("YYYYMMDD"),
                        salonIds: this.salonIds
                    });

                    if (response.data.meta.success) {
                        this.data = response.data.data.map( item => {
                            return {...item, correctPercent:  Math.round(item.totalTurnPass / item.totalDays * 100)}
                        });
                    }
                } catch (e) {
                    if (e.message.includes("401")) {
                        await this.refreshToken();
                        this.handleGetReport();
                    } else {
                        this.openNotification(e.message)
                    }
                } finally {
                    this.loading = false;
                }
            },
            filterOption(inputValue, options) {
                return options.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            },
            onSelectSalonChange(value) {
                this.salonIds = value === 'all' ? this.salonSelects.map(salon => salon.id):[value];
            },
            onSelectSalonAreaChange(value) {
                if (value === 'all') {
                    this.salonIds = this.getSalons.map(salon => salon.id);
                    this.disableSelectSalon = true;
                } else {
                    this.selectedSalon = 'all';
                    let salonArea = JSON.parse(value);
                    this.salonSelects = this.getSalons.filter( salon => salonArea.salonIds.includes(salon.id));
                    this.salonIds = this.salonSelects.map(salon => salon.id)
                    this.disableSelectSalon = false;
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
        },
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header__action {
        display: flex;
        flex-direction: row;
    }
    .filter-item span {
        font-weight: bold;
    }
    .report {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>